@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --overlay-background: #0003;
}

.CartOverlay {
    &::before {
        display: none;
    }

    &-ContentWrapper {
        z-index: 2;
        border: 0;
    }

    &-Promo {
        border: 2px solid $color-neutral-90;
        background: $color-neutral-5;
        color: $color-neutral-90;
        font-size: 12px;
        font-weight: 500;
        line-height: 13px;
        margin: 24px;
        padding-bottom: 0;

        p {
            margin-bottom: 10px;
        }
    }

    &-Items {
        padding: 0;
        margin: 0;

        @include scrollbar;
    }

    &-Additional {
        background: $color-neutral-20;
    }

    &-Total {
        background: none;
        padding: 24px 18px 16px;

        dt {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        dd {
            font-size: 20px;
            font-weight: 500;
            line-height: 27px;
        }
    }

    &-TaxInfo {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: $color-neutral-50;
        padding-top: 5px;
    }

    &-Actions {
        padding: 0 18px 24px;
    }

    &-Empty {
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

[dir="ltr"] {
    .CartOverlay {
        right: 0;
    }
}

@include tablet {
    .CartOverlay {
        &-ContentWrapper {
            display: flex;
            flex-direction: column;
            height: calc(100vh - 104px);
        }

        &-Promo {
            flex: 0 0 auto;
        }

        &-Items {
            flex: 1 1 auto;
            max-height: 100%;
            height: 100%;
        }

        &-Additional {
            margin-inline: -20px -20px;
            margin-bottom: -20px;
            flex: 0 0 auto;
        }

        &-Actions {
            .Button.CartOverlay-CartButton {
                width: 40%;
            }
        }
    }
}

@include desktopAndAbove {
    .CartOverlay {
        &-Items {
            padding: 17px;
            margin: 7px;
        }
    }
}

