@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CartCoupon {
    margin-bottom: 64px;

    .Form {
        display: flex;
        align-items: center;
    }

    &_isInvalid {
        .Form {
            align-items: flex-start;
        }
    }

    input {
        height: 55px;
    }

    &-Apply {
        margin-left: 24px;
    }

    &-Input_wrongCoupon {
        .Field_type_text.Field_isValid input#couponCode {
            border-color: $color-secondary-70;
        }
    }

    &-Message {
        margin: 0;

        strong {
            margin-left: 8px;
        }
    }

    .Field {
        margin-bottom: 0;
    }

    &-Remove {
        background: $color-neutral-20;
        color: $color-neutral-70;
        border-color: $color-neutral-20;
        
        &:hover {
            background: $color-neutral-40 !important;
            border-color: $color-neutral-40 !important;
        }
    }
}

@include mobile {
    .CartCoupon {
        padding: 0;

        .Form {
            margin: 0;
            display: block;

            .Button {
                width: 100%;
                margin: 10px 0 0;
            }
        }
    }
}
