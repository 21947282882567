$white: #fff;
$black: #0a0903;
$default-primary-base-color: #000;
$default-primary-dark-color: #87b7a2;
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: #eee;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);
$font-muli: 'Muli', sans-serif;
$font-poppins: 'Poppins', sans-serif;
$font-standard-size: 62.5%;

// Widths

$page-max-width: 1416px;
$page-max-width-pdp: 1464px;
$page-max-width-cms: 1464px;
$page-max-width-cart: 1464px;
$page-padding-left-mobile: 20px;
$page-padding-right-mobile: 20px;
$page-padding-left: 15px;
$page-padding-right: 15px;

// Colors

$color-neutral-90: #000;
$color-neutral-80: #484848;
$color-neutral-70: #797979;
$color-neutral-60: #8a8989;
$color-neutral-50: #a1a1a1;
$color-neutral-40: #d1d1d1;
$color-neutral-30: #e7e7e7;
$color-neutral-20: #f3f3f3;
$color-neutral-10: #f9f9f9;
$color-neutral-5: #fff;

$color-primary-90: #3a5950;
$color-primary-80: #426b5f;
$color-primary-70: #4b8071;
$color-primary-60: #559a86;
$color-primary-50: #63b69e;
$color-primary-40: #8ac8b7;
$color-primary-30: #a7d6c9;
$color-primary-20: #bde0d6;
$color-primary-10: #cee8e0;
$color-primary-5: #daeee8;

$color-secondary-90: #982828;
$color-secondary-80: #ba2828;
$color-secondary-70: #dd2d2d;
$color-secondary-60: #eb4e4e;
$color-secondary-50: #ff5959;
$color-secondary-40: #ff9595;
$color-secondary-30: #ffafaf;
$color-secondary-20: #ffc3c3;
$color-secondary-10: #ffd2d2;
$color-secondary-5: #ffdddd;

$color-other-1: #82a2f6;
$color-other-2: #144fe8;
$color-other-3: #f7a912;

$white-transparent: rgba(255, 255, 255, 0.8);
