@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CartItem {
    border-color: $color-neutral-30;

    &-Wrapper_isMobileLayout {
        grid-template-columns: 110px auto;
        grid-gap: 16px;
    }

    &-Wrapper_isCartMobile {
        .CartItem {
            &-Heading {
                font-size: 12px;
                margin-top: 0;
            }

            &-Title {
                margin-bottom: 0;
            }

            &-Qty {
                width: 70px;
                height: 42px;
            }
        }
    }

    &-Picture_isMobileLayout.Image {
        width: 100px;
        margin-right: 12px;
    }

    &-Price_isCartOverlay {
        data {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }
    }

    &-Wrapper_isCart {
        display: grid;
        padding: 0;
        align-items: flex-start;
        position: relative;
    }

    &-DescriptionWrapper {
        flex: 1 1 auto;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
    }

    &-Title {
        width: auto;
        padding-right: 20px;
    }

    &-Heading {
        white-space: initial;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;

        a {
            text-decoration: none;
            color: inherit;
        }

        @supports (-webkit-line-clamp: 2) {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    &-Options {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: $color-neutral-50;
    }

    &-ShippingTime {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.05em;
        display: flex;
        align-items: center;
        white-space: normal;
        flex-wrap: wrap;

        svg {
            margin: 0 5px 2px 0;
        }

        strong {
            font-weight: 600;
            white-space: nowrap;
        }
    }

    &-ItemPrice {
        text-align: right;
    }

    &-Price {
        justify-content: flex-end;

        &_isMobileLayout {
            font-weight: 400;
        }

        &.ProductPrice>span {
            margin: 0;

            &.ProductPrice-HighPrice {
                margin-top: 4px;
            }
        }
    }

    &-Actions {
        display: flex;
    }

    &-Button {
        display: flex;
        align-items: center;
        color: $color-neutral-50;

        svg {
            margin-right: 8px;
        }

        +.CartItem-Button {
            margin-left: 48px;
        }
    }

    .CartItem-QuantityWrapper {
        &_isCartOverlay {
            .CartItem-Qty {
                width: 70px;

                input {
                    --input-type-number-height: 40px;
                }
            }
        }
    }
}

[dir="ltr"] .CartItem-Wrapper_isCart .CartItem-Title {
    padding-left: 0;
}

@include tabletAndAbove {
    .CartItem {
        &-Wrapper_isCart {
            grid-template-columns: 1fr 94px 88px 88px;
            margin: 16px 0;
            grid-gap: 16px;

            .CartItem-Picture {
                flex: 0 0 180px;
                width: 180px;
                height: 150px;
            }


            .CartItem-QuantityWrapper {
                padding-bottom: 102px;
                justify-content: flex-end;
                display: flex;
            }
        }

        &-ProductInfo,
        .ProductPrice {
            padding-top: 1.5rem;
        }

        &-DescriptionWrapper {
            padding-left: 16px;
        }

        &-Actions {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
}

@include desktopAndAbove {
    .CartItem {
        &-Wrapper_isCart {
            margin: 16px 20px;
            grid-template-columns: 1fr 115px 130px 100px;
            grid-gap: 20px;

            .CartItem-Picture {
                flex: 0 0 200px;
                width: 200px;
                height: 150px;
            }
        }

        &-DescriptionWrapper {
            padding-left: 24px;
        }
    }
}

@include mobile {
    .CartItem {
        &-Wrapper_isCartMobile {
            display: block;
            padding: 24px 0;
        }

        &-ProductDetails {
            display: grid;
            grid-template-columns: 1fr 70px 80px;
            grid-gap: 20px;
            align-items: center;
            padding-top: 30px;

            > div {
                height: 100%;
                display: flex;
                align-items: center;

                &.CartItem-ProductDetailsSubtotal {
                    align-items: start;
                    justify-content: right;
                }
            }
        }

        &-ShippingTime {
            flex-wrap: nowrap;
            padding-top: 0;
            width: 100%;
            font-size: 12px;
            line-height: 18px;

            svg {
                flex: 0 0 21px;
            }
        }

        &-ProductDetailsLabel {
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: $color-neutral-70;
            margin-bottom: 9px;
            text-align: right;
            display: block;
            position: absolute;
            top: -24px;
            width: 100%;
            left: 0;
        }

        &-Price {
            margin-bottom: 0;
            font-size: 14px;

            .ProductPrice-HighPrice {
                font-size: 12px;
            }
        }

        &-Button {
            font-size: 12px;
        }
    }
}
