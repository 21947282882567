@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CartPage {
    @include page-container;

    max-width: $page-max-width-cart;

    &-Overflow {
        padding: 0;
    }

    &-Heading {
        &_isBasketEmpty {
            text-align: center;
        }
    }

    &-Wrapper {
        &_isBasketEmpty {
            grid-template-columns: 1fr;
            text-align: center;
        }
    }

    &-SubHeading {
        font-size: 16px;
        line-height: 24px;
        color: $color-neutral-50;
        margin-bottom: 64px;
    }

    &-Floating {
        margin-top: 0;
    }

    &-TableHead {
        background: none;
        border-bottom: 1px solid $color-neutral-30;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.05em;
        color: $color-neutral-70;
        text-transform: initial;
    }

    &-ExpandableContentHeading {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    &-CheckoutButton,
    &-ContinueButton {
        width: 100%;
    }

    &-CheckoutButton {
        margin: 0 0 16px;
    }

    &-PromoBlock {
        border: 1px solid $color-neutral-30;
        padding: 18px 24px;

        >svg {
            flex: 0 0 32px;
        }
    }

    &-PromoText {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.05em;
        color: $color-neutral-70;
        padding-left: 26px;

        strong {
            color: $color-neutral-90;
            font-weight: 400;
        }
    }

    &-Empty {
        margin-top: 6.5rem;
        text-align: center;
    }

    &-BackShopping {
        margin-top: 6rem;
        margin-bottom: 2rem;

        &:hover {
            color: var(--button-color);
        }
    }

    &-InfoText {
        margin-top: 2rem;
        font-size: 2rem;
    }

    .AutoRelatedSlider {
        margin-bottom: 200px;
    }

    .CartPage {
        &-Discount {
            border: 0;
        }
    }
}

[dir="ltr"] .CartPage-TableHead span {
    text-align: right;

    &:first-of-type {
        text-align: left;
    }
}

@include mobile {
    .CartPage {
        &-Heading {
            display: block;
        }

        &-SubHeading {
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 32px;
        }

        &-Items {
            border-top: 1px solid $color-neutral-30;
        }

        &-PromoBlock {
            margin: 24px 0 56px;
        }
    }
}

@include tabletAndAbove {
    .CartPage {
        &-Wrapper {
            display: block;
        }

        &-TableHead {
            background: none;
            grid-template-columns: 1fr 94px 88px 88px;
            padding: 0 0 16px;
            grid-gap: 16px;

            >span:first-of-type {
                padding-left: 10px;
            }

            >span:last-of-type {
                padding-right: 10px;
            }
        }
    }
}

@include desktopAndAbove {
    .CartPage {
        &-TableHead {
            background: none;
            padding: 0 0 16px;
            grid-template-columns: 1fr 115px 130px 100px;
            grid-gap: 20px;

            >span:first-of-type {
                padding-left: 10px;
            }

            >span:last-of-type {
                padding-right: 10px;
            }
        }
    }

    [dir="ltr"] .CartPage-TableHead span {
        text-align: center;

        &:first-of-type {
            text-align: left;
        }

        &:last-of-type {
            text-align: center;
        }
    }
}

@include above1200 {
    .CartPage {
        padding-left: 44px;
        padding-right: 44px;
        overflow-x: hidden;

        &-Wrapper {
            display: grid;
            grid-template-columns: 1fr 326px;

            &_isBasketEmpty {
                grid-template-columns: 1fr;
                text-align: center;
            }
        }

        &-TableHead {
            background: none;
            grid-template-columns: 1fr 115px 115px 115px;
            padding: 0 20px 16px;
            grid-gap: 20px;
        }
    }

    [dir="ltr"] .CartPage-TableHead span {
        text-align: right;

        &:last-of-type {
            text-align: right;
        }
    }
}
